<template>
    <div class="tooltip-wraper">
        <font-awesome-icon icon="question"/>
        <div class="helptext-content tooltip-left tooltip-xl-bottom" v-html="text"></div>
    </div>
</template>

<script>
import { library } from '@fortawesome/fontawesome-svg-core';
import { faQuestion } from '@fortawesome/free-solid-svg-icons';

library.add(faQuestion);

export default {
    name: "Tooltip",
    props: {
        text: {
            type: String,
            default: ''
        },
    },
}
</script>

<style lang="scss" scoped>
.helptext {
	position: relative;

	&:hover {
		.helptext-content {
			display: block;
			z-index: 1;
		}
	}

	&-content {
		position: absolute;
		top: calc(100% + 10px);
		left: 50%;
		transform: translateX(-50%);
		background: #fff;
		display: none;
		padding: 10px;
		border-radius: 5px;
		text-transform: none;
		color: #2D2E30;
		width: fit-content;
		max-width: 400px;
		min-width: 100px;
		box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.07);
		font-size: 0.75rem;
		opacity: 1;

		&:before {
			position: absolute;
			content: '';
			width: 8px;
			height: 8px;
			top: -4px;
			left: 50%;
			transform: translateX(-50%) rotate(-45deg);
			background: #fff;
		}

		&.tooltip-left {
			left: unset;
			right: calc(100% + 10px);
			transform: translateY(-50%);
			top: 50%;

			&:before {
				top: 50%;
				left: unset;
				right: -8px;
			}
		}

		&.tooltip-right {
			right: unset;
			left: calc(100% + 10px);
			transform: translateY(-50%);
			top: 50%;

			&:before {
				top: 50%;
				right: unset;
				left: -8px;
			}
		}

		&.tooltip-top {
			bottom: calc(100% + 10px);
			top: unset;

			&:before {
				top: unset;
    			bottom: -5px;
			}
		}

		&.mw-150 {
			min-width: 150px;
		}

		@media (min-width: 1200px) {
			&.tooltip-xl-bottom {
				top: calc(100% + 10px);
				left: 50%;
				right: unset;
				transform: translateX(-50%);
	
				&:before {
					top: -4px;
					left: 50%;
					right: unset;
				}
			}
		}
	}
}

.tooltip-wraper {
    position: absolute;
    right: 0;
    top: 0;
    width: 26px;
    height: 26px;
    cursor: help;
    border-radius: 16px;
    background: #14AAF5;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9;
    box-shadow: 0 0 7px 0.1px #14AAF5;

    &:hover {
        .helptext-content {
            display: block;
        }
    }

    svg {
        font-size: 0.9rem;
    }

    .helptext-content {
        width: max-content;
        max-width: 65vw;

        @media (min-width: 576px) {
            max-width: 500px;
        }

        & ::v-deep *:last-child {
            margin-bottom: 0;
        }
    }
}

</style>